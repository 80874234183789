import { cn } from '~/libs/cn'
import type { Component } from 'solid-js'

interface LogoOutlineProps {
  class?: string
  id?: string
}

export const LogoIcon: Component<LogoOutlineProps> = (props) => {
  return (
    <svg
      class={cn('size-28', props.class)}
      height="800"
      id={props.id}
      viewBox="0 0 800 800"
      width="800"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        clip-path="url(#clip0_114_302)"
        fill="currentColor"
      >
        <path
          class="text-brand-slate"
          d="M472.155 403.236a69.13 69.13 0 0 1-11.702 38.477 69.4 69.4 0 0 1-31.162 25.509 69.6 69.6 0 0 1-40.119 3.941 69.5 69.5 0 0 1-35.553-18.954 69.2 69.2 0 0 1-19.004-35.462 69.1 69.1 0 0 1 3.952-40.016 69.3 69.3 0 0 1 25.574-31.082 69.6 69.6 0 0 1 65.15-6.401 69.4 69.4 0 0 1 22.528 15.013 69.3 69.3 0 0 1 15.052 22.47 69.1 69.1 0 0 1 5.284 26.505"
        />
        <path
          class="text-brand-orange"
          d="M460.497 580.119v49.634a59.05 59.05 0 0 1-17.483 41.583 59.36 59.36 0 0 1-41.792 17.195 59.35 59.35 0 0 1-41.791-17.195 59.05 59.05 0 0 1-17.484-41.583v-49.634a59 59 0 0 1 4.412-22.731 59.1 59.1 0 0 1 12.826-19.299 59.3 59.3 0 0 1 19.273-12.905 59.4 59.4 0 0 1 45.528 0 59.3 59.3 0 0 1 19.273 12.905 59.1 59.1 0 0 1 12.826 19.299 59 59 0 0 1 4.412 22.731"
        />
        <path
          class="text-brand-aqua"
          d="M460.497 171.145v49.633a59.05 59.05 0 0 1-17.483 41.583 59.36 59.36 0 0 1-41.792 17.195 59.35 59.35 0 0 1-41.791-17.195 59.05 59.05 0 0 1-17.484-41.583v-49.633a59 59 0 0 1 4.412-22.732 59.1 59.1 0 0 1 12.826-19.298 59.3 59.3 0 0 1 19.273-12.906 59.4 59.4 0 0 1 45.528 0 59.3 59.3 0 0 1 19.273 12.906 59.1 59.1 0 0 1 12.826 19.298 59 59 0 0 1 4.412 22.732"
        />
        <path
          class="text-brand-blue"
          d="m628.667 303.174-35.186 35.095a59.35 59.35 0 0 1-41.913 17.317 59.35 59.35 0 0 1-41.913-17.317 59.04 59.04 0 0 1-17.361-41.805 59.04 59.04 0 0 1 17.361-41.805l35.185-35.096a59.36 59.36 0 0 1 41.914-17.316 59.35 59.35 0 0 1 41.913 17.316 59.05 59.05 0 0 1 17.361 41.806 59.04 59.04 0 0 1-17.361 41.805"
        />
        <path
          class="text-brand-green"
          d="m585.002 671.63-35.186-35.095a59.1 59.1 0 0 1-12.849-19.181 58.98 58.98 0 0 1 0-45.249 59.1 59.1 0 0 1 12.849-19.181 59.3 59.3 0 0 1 19.23-12.816 59.4 59.4 0 0 1 45.367 0 59.3 59.3 0 0 1 19.23 12.816l35.185 35.095a59.1 59.1 0 0 1 12.849 19.181 59 59 0 0 1 0 45.25 59.1 59.1 0 0 1-12.849 19.18 59.3 59.3 0 0 1-19.23 12.816 59.4 59.4 0 0 1-45.366 0 59.3 59.3 0 0 1-19.23-12.816"
        />
      </g>
    </svg>
  )
}
